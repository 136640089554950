.signin_row {
  margin-top: 50px;
}
.signin_container {
  width: 500px;
  padding: 40px 50px 40px 50px;
  margin-bottom: 50px;
  margin-top: 20px;
  background: @white;

  .logo_signin {
    width: 291px;
    margin-bottom: 10px;
  }

  .signin_title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 20px 0px;
  }

  .signin_forgot {
    cursor: pointer;
    text-align: center;
    color: @link-blue;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
  }

  .caulate_trial {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0px;

    &_link {
      cursor: pointer;
      color: @link-blue;
      text-decoration: underline;
    }
  }

  .signin_note {
    border-top: 1px solid @gray-1;
    margin-top: 50px;
    padding-top: 12px;
    font-size: 12px;
    font-weight: 500;

    p {
      margin-bottom: 5px;
      line-height: 16px;
      color: @grey-2;
    }
  }
}

.message {
  background: @white;
  width: 500px;
  text-align: center;
  padding: 26px;

  &_title {
    color: @white;
    font-size: 16px;
    font-weight: 700;
  }

  &_content {
    color: @black;
    font-size: 14px;
    font-weight: 500;
    p {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .signin_container {
    width: 500px;
    padding: 40px 15px 40px 15px;
    margin-top: 0;
    .logo_signin {
      width: 170px;
    }
    .signin_note {
      font-size: 11px;
    }
  }
}
