.ant-layout-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  height: 60px;
}

img.logo-header {
  width: 85px;
  cursor: pointer;
}
