.ant-card {
  border: none !important;
}

.ant-card-head {
  border-bottom: none;
  padding: 20px;
  .ant-card-head-title {
    padding: 0;
    .ant-typography {
      font-weight: @bold;
      color: @darken-primary;
      font-size: @h1;
      line-height: @line_hight_h1;
    }
  }
}

.ant-card-body {
  padding: 0 20px 0 20px;
  .ant-divider-horizontal {
    margin: 0 0 7px 0;
  }
  .card_title {
    color: @darken-primary;
  }
}

@media screen and (max-width: 768px) {
  .ant-card-body {
    padding: 0;
  }
}
