.input_range_picker {
  align-items: center;
  margin-bottom: 17px !important;
  .ant-form-item-label label {
    margin-bottom: 0 !important;
  }
  .ant-input-affix-wrapper {
    padding: 0 8px;
    border-color: @black;
    .ant-input-prefix {
      margin-right: 0;
    }
    .ant-input:focus {
      border: unset;
    }
  }
}
