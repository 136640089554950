.ant-breadcrumb {
  ol {
    padding: 0;
  }

  .ant-breadcrumb-separator {
    display: none;
  }

  .ant-breadcrumb-link {
    font-size: 12px;
    font-weight: 500;
    color: @grey-2;
  }

  a {
    color: @grey-2;
  }

  .ant-divider {
    color: @grey-2;
    font-size: 14px;
    font-weight: 500;
  }

  li:last-child a {
    color: @black;
  }
}