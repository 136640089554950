.ant-menu-submenu-popup {
  .ant-menu-submenu-vertical {
    &:hover {
      background: @light-bg !important;
    }
    .ant-menu-submenu-title:hover {
      color: @black;
    }
  }
}
.main-menu {
  border-bottom: 1px solid @primary-color;
  .menu-container {
    display: flex;
    flex-direction: row;
    .header-menu {
      flex: 8;
      .anticon-home {
        color: @black;
      }
      .pdf-2 {
        padding: 0 0px 0 20px !important;
        a {
          text-decoration: underline;
          font-size: 10px;
        }
      }
      .url-company {
        display: inline-block;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;
        font-weight: 700;
        color: @black;
        padding: 0 20px 0 0px !important;
        a {
          text-decoration: underline;
          font-size: 10px;
        }
      }
      .not-catalog {
        &::before {
          display: none;
        }
      }
    }
    .account-menu {
      flex: 2;
      .account-menu-container {
        width: 130px;
        float: right;
        .account-menu-item {
          display: flex;
          height: 60px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            color: @grey-3;
            font-size: 18px;
          }
          p {
            font-size: 9px;
            font-weight: 700;
            color: @grey-3;
            margin-bottom: 0;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .header_tab {
    display: flex;
    align-items: center;
  }
}
.ant-menu-horizontal {
  border-bottom: none;
  height: 60px;
  line-height: 60px;
  &:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 4px;
    .anticon {
      margin-left: 15px;
    }
  }
  .ant-menu-item {
    padding: 0 20px 0 20px !important;

    &:after {
      display: none;
    }

    .ant-menu-title-content {
      font-size: 16px;
      font-weight: 700;
      padding: 15px 0 14px 0;

      a {
        color: @grey-3;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .ant-menu-submenu {
    &:after {
      display: none;
    }

    .ant-menu-title-content {
      font-size: 16px;
      font-weight: 700;
      padding: 15px 23px 15px 23px;
      color: @white;
    }
  }

  .active {
    background: @darken-primary;
    top: 0;
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .main-menu {
    display: none;
  }
}
