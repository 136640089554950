.ant-picker-cell-in-view{
  &:hover {
    .ant-picker-cell-inner{
      background-color: @primary-color !important;
    }  
  }
  .ant-picker-cell-inner{
    color: @black !important;
    &_red{
      color: @primary-color !important;
    }
    &_blue{
      color: @link-blue !important;
    }
  }
}
.ant-picker-cell{
  color: @gray-1;
}

.ant-picker-footer-extra{
  justify-content: center;
  display: flex;
}