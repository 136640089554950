.row_detail {
  .title .ant-typography{
    font-weight: 700;
    font-size: 14px;
    color: @black;
    margin-right: 5px;
  }
  .value .ant-typography{
    font-weight: normal;
    font-size: 14px;
  }
}