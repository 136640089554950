.account_setting {
  &_text_link {
    font-weight: @normal;
    font-size: @xs;
    line-height: @sm;
    color: @grey-2;
    &_active {
      color: @black !important;
      background-color: transparent !important;
      border: none;
    }
  }
  &_text_red {
    font-weight: @medium !important;
    font-size: @sm !important;
    line-height: @lg-s !important;
    color: @primary-color !important;
  }
  &_text_name {
    color: @black !important;
  }
  &_block_infor {
    border: 1px solid @primary-color;
    border-radius: 3px;
    box-sizing: border-box;
  }

  &_label {
    font-weight: @bold !important;
    font-size: @sm !important;
    line-height: @xxl !important;
    color: @black !important;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-btn {
    padding: 4px 24px;
    font-size: @sm;
  }
  .delivery {
    .ant-form-item-label {
      display: none;
    }
  }
  .destination_action {
    text-align: end;
  }
}

.button_home {
  width: 14px;
}

@media screen and (max-width: 768px) {
  .account_setting {
    &_block_infor_row {
      border-bottom: 1px solid @container-bg;
      padding: 0 0 10px 0;
      align-items: flex-start;
    }
    .ant-btn {
      padding: 4px 16px;
    }
    .ant-form-item {
      width: 90%;
    }
    &_label_container {
      margin-top: 0 !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .account_setting {
    .ant-btn {
      margin-left: 24px;
      margin-top: 0;
    }
  }
}
