.menu_mobile {
  position: absolute;
  top: 61px;
  right: 0;
  z-index: 99;
  background: @darken-primary;
  transition: all 0.5s ease;
  color: @white;
  display: flex;
  flex-direction: column;
  .top-menu {
    .ant-menu-inline {
      background: @darken-primary;
      .ant-menu-item {
        .ant-menu-item-icon {
          path {
            fill: @white;
          }
        }
      }
      .ant-menu-item-selected {
        background-color: @darken-primary;
      }
      a {
        color: @white;
      }
      span {
        color: @white;
      }
      .pdf-2 {
        width: 50%;
        background-color: @darken-primary !important;
        color: @white !important;
        &:hover {
          color: @white !important;
        }
        a {
          text-decoration: underline;
          font-size: 10px;
        }
      }
      .url-company {
        &:hover {
          color: @white !important;
        }
        &::before {
          display: inline-block;
          text-align: center;
          margin-left: 5px;
          margin-right: 5px;
          font-weight: 700;
          color: @white;
          content: '|';
        }
        padding: 0 20px 0 0px !important;
        width: 50%;
        background-color: @darken-primary !important;
        color: @white !important;
        position: absolute;
        bottom: 146px;
        right: 44px;
        a {
          text-decoration: underline;
          font-size: 10px;
        }
      }
      .not-catalog {
        &::before {
          display: none;
        }
        position: relative;
        padding-left: 24px !important;
        right: 0;
        bottom: 0;
      }
    }
    .ant-menu-sub {
      background: rgba(35, 24, 21, 0.2);
      color: @white;
    }
    .ant-menu-submenu-title {
      color: @white;
    }
  }
  .lang-select-mobile {
    margin: 0 auto;
    height: 100px;
    .ant-select {
      width: 210px;
      height: 30px;
      color: @white;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      position: relative;
      background-color: transparent;
      border: 1px solid @white;
      border-radius: 3px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  .menu-mobile-account {
    height: 40px;
    margin: 1px 50px;
    .account-menu-container {
      .account-menu-item {
        display: flex;
        height: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          color: @white;
          font-size: 18px;
        }
        p {
          font-size: 9px;
          color: @white;
          margin-bottom: 0;
          margin-top: -20px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .menu_mobile {
    display: none;
  }
}
