.ant-btn-ghost {

  &:hover,
  &:focus {
    background: @darken-grey;
    border-color: @darken-grey;
    color: white;
  }
}

.ant-btn-primary {
  font-weight: 700;
  font-size: 16px;
  height: 44px;
  &:hover,
  &:focus {
    background: @darken-primary;
    border-color: @darken-primary;
    color: white;
  }
}

.ant-btn[disabled] {

  &,
  &:hover {
    background: @dark-disabled;
    border-color: @dark-disabled;
    color: white;
  }
}


@media screen and (max-width: 768px) {
  .ant-btn-primary {
    height: 44px;
  }
}