.search_mobile {
  position: absolute;
  top: 61px;
  left: 0;
  right: 0;
  z-index: 99;
  transition: all 0.5s ease;
  color: @white;
  background: @white;
  height: 60px;
  padding: 8px 15px;
  border-bottom: 1px solid @gray-1;
}

@media screen and (min-width: 768px) {
  .search_mobile {
    display: none;
  }
}
