.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
}

.loading_overlay {
  display: flex;
  justify-content: center;
  padding-top: 350px;
  width: 100%;
  margin-top: 100px;
  position: absolute;
  background: @bg-overlay;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}
