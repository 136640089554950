.policy_page {
  &_card {
    max-width: 1220px;
    margin: 60px auto 70px;
    .ant-card-body {
      padding: 0 !important;
    }
  }

  .iframe_section {
    iframe {
      width: 100%;
      height: 600px;
      border: 1px solid @primary-color;
      border-radius: 5px;
    }
    margin: 95px 105px 30px;
  }

  .button_section {
    margin-bottom: 80px;
    .ant-btn {
      min-width: 170px !important;
    }
    .button {
      &_disagree {
        background-color: @white;
        color: @primary-color;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .iframe_section {
      margin: 95px 45px 30px;
    }
  }

  @media screen and (max-width: 576px) {
    .ant-btn {
      margin-bottom: 10px !important;
    }
    .iframe_section {
      margin: 95px 15px 20px;
    }
  }
}
