.chamfer_modal {
  max-width: @width_modal;

  .ant-tabs-nav {
    border-bottom: 1px @red-base solid;
  }

  .ant-tabs-content {
    margin-top: -15px;
  }
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: left;
    .ant-tabs-tab-active {
      background-color: @primary-color;
      .ant-tabs-tab-btn {
        color: @white;
      }
    }
    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 120px;
      height: 44px;
      border-color: @primary-color !important;
      font-weight: @bold;
      color: @primary-color;
      border-bottom: none;
      @media (max-width: 768px) {
        width: 100px;
        height: 38px;
      }
    }
  }

  .button-specified-all {
    border-color: @primary-color !important;
    font-weight: @bold;
    color: @primary-color;
  }
  .button-specified.active {
    color: @white !important;
  }
  &_container.active {
    background-color: @red-base !important;
  }
  &_specified-all {
    display: flex;
    justify-content: flex-end;
  }

  .custom_card {
    .ant-card-head-wrapper {
      border-bottom: @primary-color 1px solid;
    }
  }
  .ant-card-head {
    padding: 0 !important;
  }
  &_image {
    display: flex;
    justify-content: center;
    padding-top: 11px;
  }
  &_container {
    background-color: @light-bg;
  }

  &_container.active {
    .description {
      color: @white;
    }
  }
  &_title {
    display: flex;
    align-items: center;
  }

  img {
    width: 143px;
    height: 103px;
    padding: 10px;
    background: @white;
    @media (max-width: 768px) {
      width: 135px;
      height: 97px;
    }
  }

  .description {
    padding: 15px;
    text-align: center;
    font-weight: @bold;
    color: @grey-2;
  }
  .ant-divider {
    border-bottom: 1px @red-base solid;
  }

  @media (min-width: 768px) {
    .ant-tabs-tab-btn {
      font-size: 16px;
    }
    .button-specified-all {
      font-size: 16px;
      width: 170px;
      height: 30px;
      display: flex;
      justify-content: center;
      span {
        margin-top: -5px;
      }
    }
  }

  &_container:hover {
    cursor: pointer;
    background-color: @red-base;
    .description {
      color: @white;
    }
  }

  .button_submit_notification {
    width: 170px;
    height: 44px;
  }

  .ant-form-item-control-input {
    min-height: 0;
  }

  @media (max-width: 768px) {
    padding: 20px 10px !important;
    .contain-image {
      margin-left: -10px !important;
      margin-right: -10px !important;
      .ant-col {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
    .description {
      padding: 7px;
    }
    .ant-tabs-tab {
      max-width: 45px;
      max-height: 44px;
      border: none !important;
      color: @grey-2;
      background-color: white !important;
    }
    .ant-tabs-tab-active {
      background-color: @red-base !important;
    }

    .button-specified-all {
      max-height: 27px;
      border: none;
      font-weight: normal;
      color: @red-base;
    }
  }
}
