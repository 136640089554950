.reset_pass {
  width: 500px;
  padding: 70px 50px 70px 50px;
  margin-top: 60px;
  margin-bottom: 77px;
  background: @white;

  &_logo {
    width: 291px;
    margin-bottom: 70px;
  }

  button {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .reset_pass {
    padding: 70px 15px 70px 15px;
    min-height: 668px;
    margin-bottom: 0;
    &_logo {
      width: 170px;
    }
  }
}
