.product_page {
  .product {
    &_card {
      border-radius: 20px;
      border: 1px solid @primary-color;
      background: @grey-4;
      padding: 10px 40px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      &_col {
        display: flex;
        width: 100%;
      }
      .card_info {
        justify-content: center;
        width: 100%;
        &_item {
          display: flex;
          align-items: center;
          width: 100%;
        }
        &_title {
          color: @grey-3;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        &_content {
          margin-left: 10px;
          color: @grey-3;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }
    &_image {
      img {
        height: 74mm;
        width: 105mm;
      }
    }
    &_pdf {
      width: 100%;
      height: 700px;
    }
    &_tabs {
      margin-top: 20px;
      .ant-tabs-nav::before {
        border-bottom: 1px solid @grey-2;
      }
      .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 0;
      }
      .ant-tabs-tab {
        border: 1px solid @grey-2;
        .ant-tabs-tab-btn {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          color: @grey-2;
        }
      }
      .ant-tabs-tab-active {
        background-color: @primary-color;
        border: unset;
        .ant-tabs-tab-btn {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          color: @white;
        }
      }
      .ant-tabs-tab-disabled {
        background: @dark-disabled;
        border-color: @dark-disabled;
        .ant-tabs-tab-btn {
          color: @white;
        }
      }
    }
    &_table {
      &_text {
        text-align: center;
      }
    }
  }

  .custom_table {
    margin-top: 0;
    table {
      border-collapse: collapse;
    }
    .ant-table-thead th {
      background-color: @white;
      border-bottom: unset;
    }
    .ant-table {
      border: unset;
      overflow: auto;
      max-height: 700px;
      z-index: 1;
    }
    .ant-table-cell {
      border: 1px #c9caca solid;
      color: #000000;
      .ant-row {
        justify-content: center;
      }
    }
    .snpo1_link {
      cursor: pointer;
      text-align: center;
      color: @link-blue;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media (max-width: 768px) {
    .product {
      &_card {
        flex-direction: column-reverse;
        padding: 10px;
        &_col {
          width: 100%;
        }
        .card_info {
          margin-top: 22px;
          flex-direction: row;
          width: 100%;
          align-items: flex-start;
          &_content {
            text-align: center;
            margin-left: unset;
          }
          &_item {
            align-items: center;
            width: 50%;
            text-align: center;
            justify-content: center;
            flex-direction: column;
          }
        }
      }
      &_image {
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .product_page_trial {
    margin-top: 75px !important;
  }
}
