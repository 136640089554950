.date_range_picker {
  align-items: baseline;
  margin-bottom: 19px !important;
  .ant-picker {
    height: 44px;
    border: 1px solid @black;
    .ant-picker-suffix {
      color: @primary-color;
    }
  }
  .ant-form-item-label label {
      margin-bottom: 0 !important;
  }
}
