.order-page {
  .item-counter-index {
    color: white;
    margin-bottom: 0px;
  }

  .btn-back {
    font-size: 1em;
    height: 44px;
    color: @primary-color;
    border: 1px solid @primary-color;
    font-weight: 700;
  }

  h3 {
    margin-bottom: 0px;
  }

  .highlight {
    color: @primary-color;
    margin-left: 1em;
  }

  .d-flex-1 {
    display: flex;
    flex: 1;
  }

  .divider {
    display: none;
  }

  .notes {
    margin-top: 2rem;
    h5 {
      font-size: 14px;
      color: @primary-color;
    }
  }

  .noti {
    margin-top: 10px;
    font-size: 14px;
    color: @primary-color;
  }

  .shipping-method {
    display: flex;
    flex-direction: column;
    white-space: pre-line;
  }
  .delivery {
    .ant-form-item {
      margin: 5px 0px 0px;
    }
  }
  .web-mitr-no {
    display: none;
  }

  .item-counter {
    justify-content: center;
  }

  .item-label {
    display: flex;
    align-items: center;
    max-height: 44px;
    h5 {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }

  .included_tax {
    justify-content: end;
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .sm-flex-1 {
      flex: 1;
    }
    .divider {
      display: block;
    }
    .margin-sm {
      margin: 1em 0em;
    }

    .ant-picker {
      height: 44px;
    }

    .item-counter {
      justify-content: flex-start;
    }

    .item-info {
      display: none;
    }

    .included_tax {
      justify-content: space-around;
      margin-top: 0px;
    }

    .delivery {
      display: flex;
      align-items: baseline;
    }
  }

  .back-order-product {
    .price {
      display: flex;
      text-align: center;
    }
  }
}
