@font-face {
  font-family: 'Yu Gothic';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/YuGothM.ttc');
}

@font-face {
  font-family: 'Yu Gothic';
  font-weight: bold;
  font-style: normal;
  src: url('../assets/fonts/YuGothB.ttc');
}

.setup_font(@lang) {
  @font-family: 'font-family-@{lang}';
  body {
    font-family: @@font-family;
  }
  button {
    font-family: @@font-family;
  }
  input {
    font-family: @@font-family;
  }
}

.required_language(@lang) {
  .content_lang {
    content: '*';
    width: auto;
    background: none;
    color: @primary-color;
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::after {
      display: block;
      .content_lang();
    }
    &::before {
      display: none;
    }
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    .content_lang();
  }
  .input_tag .ant-form-item-label > label.ant-form-item-no-colon {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
  .form_label_required {
    &::after {
      display: block;
      .content_lang();
    }
    &::before {
      display: none;
    }
  }
  .form_label_no_required {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}

html[lang='ja'] {
  .setup_font('ja');
}

html[lang='en'] {
  .setup_font('en');
  .required_language('en');
}

html[lang='vi'] {
  .setup_font('vi');
  .required_language('vi');
}

html[lang='ko'] {
  .setup_font('ko');
  .required_language('ko');
}

html[lang='th'] {
  .setup_font('th');
  .required_language('th');
}

html[lang='zh'] {
  .setup_font('zh');
  .required_language('zh');
}

html[lang='tw'] {
  .setup_font('tw');
  .required_language('tw');
}
