.back_top {
  right: 40px;
  bottom: 120px;
  &_content {
    background: @primary-color;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: @white;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 768px) {
  .back_top {
    display: none;
  }
}
