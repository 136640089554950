.section_title {
  padding: 10px 0;
  .anticon {
    color: @darken-primary;
    font-size: 22px;
    font-weight: 700;
  }
  .title{
    flex-direction: row;
    display: flex;
    align-items: center;
    &_text{
      margin-left: 10px;
      color: @darken-primary;
      font-size: 30px;
      font-weight: 700;
    }
  }
  .elemen_right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span{
      color: @darken-primary;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 768px) {
  .section_title{
    flex-direction: row;
    display: flex;
    .title{
      &_text{
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
}