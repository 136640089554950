.custom_calendar {
  @media (min-width: 1200px) {
    width: 1100px;
  }
  width: 100%;
  margin: 0 auto;
  .mb-100();

  thead {
    border-top: 1px solid @dark-disabled;
    padding-top: 8px;
    th {
      padding: 8px 12px 5px 16px !important;
      text-align: left;

      &:first-child {
        .text-danger();
      }

      &:last-child {
        .text-blue();
      }
    }
  }

  .ant-picker-cell-disabled {
    background: @grey-2;
    .custom_calendar_content {
      display: none;
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: transparent !important;
    }
  }

  .ant-picker-calendar-date-value {
    display: none !important;
  }

  .ant-picker-panel {
    text-align: left !important;
    tbody {
      border: 1px solid @dark-disabled;
    }
  }

  .ant-picker-calendar-date-content {
    overflow-y: revert !important;
  }

  .ant-picker-cell-inner {
    border: 1px solid @grey-2 !important;
    margin: 0 !important;
    padding: 4px 12px 16px !important;

    .ant-picker-calendar-date-value {
      display: inline;
    }

    &.ant-picker-calendar-date-today {
      .picker_value {
        padding: 4px;
        border-radius: 50%;
        background: @primary-color;
        color: @white !important;
      }
    }

    &.ant-picker-calendar-date {
      .ant-picker-calendar-date-value {
        color: rgba(0, 0, 0, 0.85) !important;
      }
    }
  }

  .ant-picker-body {
    padding: 8px 0 0 0 !important;
  }


  &_text {
    font-size: 16px;
    text-align: center;
  }

}