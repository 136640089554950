.custom_modal {
  .ant-modal-body {
    padding: 0;

    h1 {
      text-align: left;
      height: 60px;
      padding: 0px 20px;
      margin-bottom: 0;
      background: @darken-primary;
      font-size: 16px;
      font-weight: 700;
      color: @white;
      display: flex;
      align-items: center;
    }
  }

  .ant-modal-close {
    color: @white;

    &:hover {
      color: @white;
    }
  }
}