.layout-container {
  background: @light-bg;
}

.content_home {
  min-height: 800px;
  background: @light-bg;

  .caculate-menu {
    padding: 20px 0;

    &-item {
      cursor: pointer;
      background-size: cover;
      background-color: #fff;
      height: 84px;
      border: 1px solid @primary-color;
      border-radius: 10px;
      margin: 15px 0;
      padding: 0 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 275px;
      &-caculate {
        margin-right: 15px;

        img {
          height: 44px;
          width: 44px;
          margin-right: 15px;
        }

        .content {
          display: flex;
          flex-direction: column;

          .text-title {
            font-size: 18px;
            font-weight: 700;
            color: @darken-primary;
          }

          .text-des {
            font-size: 12px;
            font-weight: 500;
            color: @black;
          }
        }
      }

      &-search {
        margin-left: 15px;

        img {
          width: 44px;
          height: 49px;
          margin-right: 15px;
        }

        .content {
          display: flex;
          flex-direction: column;

          .text-title {
            font-size: 18px;
            font-weight: 700;
            color: @darken-primary;
          }

          .text-des {
            font-size: 12px;
            font-weight: 500;
            color: @black;
          }
        }
      }

      a {
        color: @white;
      }
    }
  }

  .notification_section {
    background-color: @white;
    max-height: 550px;
    overflow-y: auto;
    margin-bottom: 40px;

    .ant-collapse-borderless {
      background-color: @white;
    }

    &_collapse {
      padding: 20px 40px;
      .ant-collapse-header {
        padding: 20px 0;
        font-size: 14px;
        font-weight: 500;
        color: @black;

        &[aria-expanded='true'] {
          color: @darken-primary;
        }
      }

      .ant-collapse-content-box {
        border-bottom: 1px solid @primary-color;
        border-top: 1px solid @primary-color;
        background: @container-bg;
        padding-top: 30px !important;
        padding: 30px;
        font-size: 14px;
        font-weight: 500;
        color: @black;
        a {
          color: @link-blue;
          text-decoration: underline;
        }
        span,
        p,
        h2,
        h3,
        h4 {
          background-color: unset !important;
          color: @black !important;
        }
      }
    }
  }

  .product_section {
    min-height: 110px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @white;
    margin-bottom: 40px;
    width: 100%;
    justify-content: flex-start;

    &_item {
      display: flex;
    }

    .products_title {
      font-size: 16px;
      font-weight: 700;
      color: @black;
      padding-right: 20px;
    }

    .products {
      span {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        color: @link-blue;
        margin: 0 10px;
        line-height: 25px;
        display: inline-block;

        &:not(:last-child):after {
          font-family: @font-family-ja;
          display: inline-block;
          text-align: center;
          margin-left: 20px;
          font-weight: 700;
          color: @black;
          content: '|';
        }
      }
    }
  }

  .pdf_section {
    height: 360px;
    margin: 10px 0;
    padding: 30px;
    background-color: @white;
    overflow-y: scroll;
    margin-bottom: 40px;

    p {
      padding: 20px 10px;
      font-size: 14px;
      font-weight: 500;
      color: @black;
      margin-bottom: 0px;
    }
  }
  .product_slides {
    margin: 0 25px;
    .slide {
      padding: 0 10px;
      img {
        width: 100%;
        height: 200px;
        cursor: pointer;
      }
      .ant-typography {
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .slick-prev:before,
    .slick-next:before {
      color: @darken-primary;
    }
  }
}

@media screen and (max-width: 768px) {
  .content_home {
    .caculate-menu {
      &-item {
        margin: 10px 0px !important;
        width: 100%;
      }
      a {
        width: 100%;
      }
    }

    .notification_section {
      margin-bottom: 20px;

      &_collapse {
        padding: 0 10px;
        .ant-collapse-content-box {
          padding-top: 10px !important;
          padding: 10px;
          font-size: 12px;
        }
      }
    }

    .product_section {
      padding: 10px 8px 8px;

      .products {
        span {
          margin: 0;
          margin-right: 10px;
          &:not(:last-child):after {
            margin-left: 10px;
          }
        }
      }

      &_item {
        display: block;
      }
    }

    .pdf_section {
      padding: 0;
    }

    .product_slides {
      .slide {
        img {
          height: 55px;
        }
        h5 {
          font-size: 12px;
        }
      }
    }
  }
}
