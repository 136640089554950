.ant-form-item-explain-error {
  line-height: @line-height-error;
  display: flex !important;
  align-items: center;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
  white-space: normal;

  > span {
    display: flex;
    align-items: center;
    &:first-child {
      margin-right: 4px;
    }
  }
}

.ant-form-item {
  margin-bottom: 12px;
  .ant-input {
    border: 1px solid @black;
    height: 44px;
    font-weight: 500;
    &:focus {
      // border-color: @primary-color;
      border: 1px solid @primary-color;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid @black;
    height: 44px;
    font-weight: 500;
    border-radius: 3px;
    .ant-select-selection-placeholder {
      padding-top: 3px;
    }
    .ant-select-selection-item {
      padding-top: 3px;
    }
  }
  .ant-select-auto-complete {
    input {
      height: 44px !important;
    }
  }
}

.ant-form-item-label {
  padding-bottom: 0 !important;

  label {
    margin-bottom: 10px !important;
    font-weight: 700;
    white-space: pre-line;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  text-align: center;
  width: 26px;
  height: 16px;
  margin-left: 4px;
  border-radius: 8px;
  font-size: 9px;
  font-weight: 700;
  background: @primary-color;
  color: @white;
  content: '必須';
}

@media screen and (max-width: 768px) {
  .ant-form-item {
    input {
      height: 44px;
    }
  }
}
