.plugin {
  .disableChatBox{
    right: 16px !important;
  }
  .qr-code{
    .qr-btn {
      z-index: 9999;
      position: fixed;
      right: 100px;
      bottom: 20px;
      cursor: pointer;
      box-shadow: rgb(0 0 0 / 15%) 0px 3px 12px;
    }

    .qr-btn .close {
      display: none
    }

    .qr-btn i {
      transition: all 0.9s ease
    }

    #check:checked~.qr-btn i {
      display: block;
      pointer-events: auto;
      transform: rotate(180deg)
    }

    .qr-btn i {
      font-size: 22px;
      color: #fff !important
    }

    .qr-btn {
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background-color: @darken-primary;
      color: #fff;
      font-size: 22px;
      border: none;
    }

    .wrapper {
      z-index: 999;
      position: fixed;
      right: 90px;
      bottom: 100px;
      background-color: #fff;
      border-radius: 10px;
      display: none;
      transition: all 0.4s;
      -webkit-box-shadow: 4px 0px 11px -4px #000000;
      box-shadow: 4px 0px 11px -4px #000000;
    }

    #check:checked~.wrapper {
      display: block;
    }

    .qr-code-form {
      padding: 15px;
    }

    .qr-code-form input,
    textarea,
    button {
      margin-bottom: 10px
    }

    .form-control:focus,
    .btn:focus {
      box-shadow: none
    }

    #check {
      display: none !important
    }

    .QrCode__Component-sc-1aawih9-0 a {
      display: none !important;
    }

  }
}
.calendar_plugin {
  display: flex;
  align-items: center;
  justify-content: center;
}