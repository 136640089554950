.content_pricing {
  .tag_label (@color, @content, @mr: 5px, @pt: 3px) {
    display: inline-block;
    text-align: center;
    width: 26px;
    height: 16px;
    border-radius: 8px;
    font-size: 9px;
    background: @color;
    color: @white;
    padding-top: @pt;
    padding-right: 1px;
    margin-right: @mr;
    content: @content;
    font-family: Yu Gothic !important;
    margin-top: 3px;
  }
  &_card {
    padding: 10px;
  }

  &_content {
    padding: 20px 0px;
    .form_typo {
      padding: 8px 0;
    }
    .form_label_no_required {
      font-weight: 700;
      font-size: 14px;
      display: flex;
      color: @black;
      white-space: pre-line;
      &::before {
        .tag_label(@grey-2, '任意', 5px, 1px);
      }
    }
    .form_label_required {
      font-weight: 700;
      font-size: 14px;
      display: flex;
      color: @black;
      &::before {
        .tag_label(@primary-color, '必須', 5px, 1px);
      }
    }
    .destination_button {
      min-width: 95px;
    }
    .user_destination {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &_input {
        width: 100%;
      }
      &_action {
        text-align: end;
      }
    }
    .category_block_infor_row {
      .category_label {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: @black;
        display: flex;
        align-items: center;
        margin-top: 7px;
      }
      .ant-radio-group {
        padding-left: 10px;
      }
    }
    .product_checkbox {
      padding: 20px;
      border: 1px solid @black;
      border-radius: 3px;
      max-height: 230px;
      overflow-y: scroll;
      .ant-radio-wrapper {
        width: 19%;
        margin-bottom: 15px;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }
    .machining_row {
      position: relative;
      .machining_checkbox {
        padding: 20px 20px 50px;
        border: 1px solid @black;
        border-radius: 3px;
        margin-bottom: 20px;
        .ant-radio-wrapper {
          width: 19%;
          margin-bottom: 15px;
        }
      }

      .machining_col:last-child {
        .machining_checkbox {
          padding: 20px 20px 20px;
        }
      }
    }
    .machining_handle {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      bottom: 30px;
      right: 20px;
      left: 14%;
      .chamfer_method {
        color: @black;
        font-size: 14px;
        font-weight: 500;
      }
      &_button {
        background: @white;
        height: 30px;
        min-width: 150px;
        padding: 0 20px;
        span {
          color: @primary-color;
          font-size: 14px;
          font-weight: 700;
        }
      }
      &_button[disabled] {
        span {
          color: @grey-2;
        }
      }
    }
    .input_properties {
      align-items: flex-start;
      label {
        height: 100%;
        .ant-typography {
          word-break: unset;
          white-space: pre-line;
        }
      }
      .after_label {
        display: flex;
        justify-content: center;
        &_text {
          font-size: 14px;
          line-height: 22px;
          color: @black;
          font-weight: 500;
        }
      }
      .ant-form-item-label label {
        margin-bottom: 0 !important;
      }
      .size_input {
        label {
          align-items: flex-end;
        }
      }
    }
    .options_size {
      &_input {
        padding-right: 40px;
        .ant-form-item-label label {
          margin-bottom: 0 !important;
        }
        .machining_select {
          margin-right: 10px;
        }
      }
      &_val {
        bottom: 0px;
        padding-left: 10px;
      }
    }
    .box_corner {
      background: @light-bg;
      border: 1px solid @black;
      min-height: 195px;
      padding: 20px;
      border-radius: 3px;
      margin-bottom: 20px;
      .box_corner {
        &_first_input {
          .ant-form-item-control {
            min-height: 118px;
          }
        }
      }
      &_container {
        height: 100%;
        .ant-col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .ant-form-item {
            margin-bottom: 0px;
          }
        }
      }
      &_box {
        justify-content: start !important;
        &_x {
          align-items: center !important;
          flex: 1;
          margin-left: 8%;
          span {
            font-size: 14px;
            font-weight: 400;
            color: @black !important;
          }
        }
        &_y {
          justify-content: center !important;
          span {
            position: absolute;
            width: max-content;
            right: 5px;
            font-size: 14px;
            font-weight: 400;
            color: @black !important;
          }
        }
      }
      &_preview {
        width: 100%;
        height: 110px;
        position: relative;
        z-index: 0;
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          background: @darken-primary;
          clip-path: polygon(
            0 20px,
            10px 10px,
            20px 0,
            calc(100% - 20px) 0,
            calc(100% - 10px) 10px,
            100% 20px,
            100% calc(100% - 20px),
            calc(100% - 10px) calc(100% - 10px),
            calc(100% - 20px) 100%,
            20px 100%,
            10px calc(100% - 10px),
            0 calc(100% - 20px),
            0 20px,
            7px calc(20px + 2.9px),
            7px calc(100% - 20px - 2.9px),
            calc(10px + 4.95px) calc(100% - 10px - 4.95px),
            calc(20px + 2.9px) calc(100% - 7px),
            calc(100% - 20px - 2.9px) calc(100% - 7px),
            calc(100% - 10px - 4.95px) calc(100% - 10px - 4.95px),
            calc(100% - 7px) calc(100% - 20px - 2.9px),
            calc(100% - 7px) calc(20px + 2.9px),
            calc(100% - 10px - 4.95px) calc(10px + 4.95px),
            calc(100% - 20px - 2.9px) 7px,
            calc(20px + 2.9px) 7px,
            calc(10px + 4.95px) calc(10px + 4.95px),
            7px calc(20px + 2.9px)
          );
        }
      }
    }
    .ant-radio-wrapper span {
      color: @black;
    }
    .ant-form-item-label label {
      color: @black;
    }
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    .tag_label(@primary-color, '必須' , 5px , 4px);
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }

  .input_tag {
    .ant-form-item-label > label.ant-form-item-no-colon::before {
      .tag_label(@grey-2, '任意' , 5px , 1px);
    }
  }

  &_table {
    .ant-table {
      background: none;
      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: none;
      }
    }
    &_title {
      margin-top: 40px;
      h1.ant-typography {
        color: @darken-primary;
      }
      .ant-divider {
        border-top: 1px solid @primary-color;
      }
      .note {
        font-size: 14px;
        color: @primary-color;
      }
    }
    tr.ant-table-expanded-row > td {
      background: unset !important;
    }
    .table_item {
      padding-bottom: 10px;
      &_row {
        margin-bottom: 0 !important;
        background: @white;
        height: 100%;
        .row_with_checkbox {
          padding-left: 36px;
        }
      }
      &_sub_row {
        margin-bottom: 0 !important;
        .row_with_checkbox {
          padding-left: 36px;
        }
      }
    }
    .visible_phone_row {
      display: none;
    }
    .ant-table.ant-table-middle .ant-table-tbody > tr > td {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      border-bottom: none;
    }
    .header_row {
      margin-left: 0;
      border-top: 1px solid #c9caca;
    }
    .row_fit {
      height: 100%;
    }
    .ant-table-row {
      position: relative;
      .ant-table-selection-column {
        label {
          position: absolute;
          left: 10px;
          top: 23px;
          z-index: 998;
        }
      }
      .ant-table-row-expand-icon-cell {
        position: absolute;
        z-index: 0;
        top: 14px;
        right: 10px;
      }
    }
    .title {
      .ant-typography {
        margin-bottom: 0;
        font-weight: 700 !important;
        font-size: 14px !important;
      }
    }
    .col_with_border {
      padding: 10px;
      border: 1px solid @gray-1;
      border-left: none;
      border-top: none;
      align-items: center;
      display: flex;
      &:first-child {
        border-left: 1px solid @gray-1;
      }
      h5.ant-typography {
        margin-bottom: 0;
        font-weight: 700;
        font-size: 14px;
        color: @black;
      }
      .ant-typography {
        font-weight: 400;
        font-size: 14px;
        color: @black;
      }
      .text_price {
        color: @primary-color;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .col_align_right {
      justify-content: flex-end;
    }
    .col_hight_light {
      background: @main-lighter;
    }
    .col_two_row {
      min-height: 86px;
    }
    .col_border_left_phone {
      border-left: none !important;
    }
    .col_border_left_desktop {
      border-left: 1px solid @gray-1 !important;
    }
    .value_break_line span {
      white-space: pre-line;
    }
    .ant-table-row-level-0 {
      &:hover {
        background: none;
        td {
          background: none;
        }
      }
    }
    .ant-table-expanded-row-level-1 {
      background-color: @light-bg;
    }
    .ant-table-row-selected > td {
      background-color: unset !important;
    }
    .ant-table-thead {
      .ant-table-cell:not(:nth-child(2)) {
        display: none;
      }
      .ant-table-cell:nth-child(2) {
        position: absolute;
        top: 8px;
        left: 2px;
        border-bottom: unset;
        background-color: @light-bg;
      }
    }
    .ant-table-title {
      padding: 0 !important;
    }
    .all {
      background-color: @light-bg;
      padding: 20px 20px 0 20px;
      align-items: baseline;
      &_checkbox {
        padding-left: 15px;
      }
    }

    .row {
      align-items: center;
      .ant-typography {
        color: @white;
        margin-bottom: 0;
      }
      &_info {
        display: none;
      }
      &_divider {
        display: none;
      }
      &_button {
        display: none;
      }
    }
    .ant-table-expanded-row {
      .ant-table-cell {
        padding: 0 !important;
      }
    }
  }
  .order_history_button {
    background: @white;
    padding: 0;
    span {
      color: @primary-color;
      font-size: 14px;
      font-weight: 700;
    }
  }
  &_note {
    margin-top: 20px;
    padding: 20px 0;
    border-top: 1px solid @primary-color;
    border-bottom: 1px solid @primary-color;
    &_text {
      color: @primary-color;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .radio_title {
    margin-bottom: 12px;
  }

  .re_estimate {
    margin-bottom: 13px;
    &_condition {
      display: flex;
      margin-top: 10px;
      align-items: center;
      .ant-typography {
        margin-right: 12px;
      }
      &:first-child {
        margin-right: 65px;
      }
      &_title {
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        color: @black;
      }
      button {
        min-width: 120px;
      }
    }
  }
  .form_divider {
    border-top: 1px solid @gray-1;
    margin: 20px 0;
  }
  .initial_estimate {
    button {
      width: 100%;
    }
  }
  .product_field_description {
    margin-bottom: 20px;
  }
  .non_user_message {
    color: @primary-color;
    margin-top: -5px;
  }
  .ant-form-item-explain-error {
    white-space: pre-line;
  }
  &_trial {
    .content_pricing_card {
      margin-top: 75px;
    }
  }
}

.product_tooltip_des {
  padding: 0 10px;
}

@media screen and (max-width: 768px) {
  .content_pricing {
    &_trial {
      margin-top: 75px !important;
    }
    &_content {
      padding: 20px 0px;
      .variety_row {
        .ant-radio-wrapper {
          width: 46%;
        }
      }
      .product_checkbox {
        .ant-radio-wrapper {
          width: 50%;
        }
      }
      .machining_row {
        .machining_checkbox {
          .ant-radio-wrapper {
            width: 50%;
          }
        }
      }
      .machining_handle {
        align-items: center;
        left: 6% !important;
      }
      .options_size {
        &_input {
          padding-right: 0px;
          .machining_select {
            margin-right: 0px;
          }
        }
      }
      .box_corner {
        &_box {
          justify-content: center !important;
          margin-top: 10px;
        }
      }
      .radio_mt {
        .ant-radio-wrapper {
          margin-bottom: 10px;
        }
      }
      .product_field {
        display: flex;
        flex-direction: column;
      }
      .input_properties {
        align-items: flex-end;
        .after_label {
          margin-top: 0 !important;
          margin-bottom: 22px;
        }
      }
      .category_block_infor_row {
        .category_label {
          margin-top: 0;
        }
        .label_shape {
          margin-top: 3px;
        }
      }
    }
    &_table {
      .header_row {
        margin-left: 0px;
      }
      .ant-table-row {
        .ant-table-selection-column {
          label {
            left: 13px;
          }
        }
      }
      .ant-table-thead {
        .ant-table-cell:nth-child(2) {
          left: 5px;
        }
      }
      .col_border_left_phone {
        border-left: 1px solid @gray-1 !important;
      }
      .col_border_left_desktop {
        border-left: none !important;
      }
    }
    .radio_title {
      margin-bottom: 0px;
    }
    .re_estimate {
      margin-bottom: 20px;
      &_condition {
        width: 100%;
        &:first-child {
          margin-right: 0;
        }
        button {
          min-width: unset;
        }
      }
    }
    .initial_estimate {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 576px) {
  .content_pricing {
    &_content {
      .options_size {
        &_input .option_c {
          .ant-select {
            margin-top: 38px;
          }
        }
      }
      .box_corner {
        &_first_input {
          .ant-form-item-control {
            min-height: 150px;
          }
        }
        &_box {
          justify-content: center !important;
          margin-top: 60px;
        }
      }
    }
    &_table {
      .ant-table-row {
        .ant-table-selection-column {
          label {
            left: -35px;
          }
        }
      }
      .visible_phone_row {
        display: flex;
      }
    }
  }
}
