.destination_modal {
  padding: 20px;
  .table_page_size {
    display: flex;
    align-items: center;
  }
  .page_size {
    .page_size_col {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 10px;
      &_text {
        color: @black;
        font-size: 14px;
        font-weight: 400;
        margin: 0px 10px;
      }
    }
    button {
      height: 32px;
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-input {
    width: 95% !important;
  }
  .ant-input-number-input {
    height: 29px;
  }
  .modal_table {
    .ant-table-content {
      margin: 20px 0;
      padding: 10px 20px;
      border: 1px solid @gray-1;
      .dark_text {
        color: @black;
        font-size: 14px;
        font-weight: 700;
      }
      .column_zip_code {
        .anticon {
          color: @primary-color;
          font-size: 14px;
          margin-right: 6px;
        }
      }
      .ant-table-cell {
        color: @black;
        &:last-child {
          padding-right: 0px;
        }
      }

      button {
        height: 32px;
      }
    }
    .ant-table {
      height: 600px;
    }
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-btn-primary {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .destination_modal {
    padding: 20px 10px;
    .page_size {
      margin-top: 20px;
      button {
        height: 30px;
      }
    }
    .modal_table {
      .ant-table-content {
        padding: 10px 10px;
      }
      button {
        width: unset;
        height: 30px;
      }
    }
    .row_responsive {
      .dark_text {
        margin-right: 5px;
      }
      .column_zip_code {
        margin-top: 8px;
      }
    }
  }
}
