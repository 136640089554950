.sign_up {
  .ant-form {
    width: 840px;
    h4.ant-typography {
      margin-bottom: 24px;
    }
    .sign_up_message {
      line-height: 18px;
      font-size: 18px;
    }
    .ant-divider {
      margin: 8px 0;
    }
    .article {
      padding: 36px 0;
    }
  }
}