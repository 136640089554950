.category {
  &_input {
    width: 50% !important;
  }
  &_small_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: @primary-color;
  }
  &_product_item {
    width: 25%;
  }
  &_link_product {
    color: @link-blue;
    padding: 7px 10px;
    span {
      text-decoration: underline;
    }
  }
  &_shape {
    height: 44px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .category_block_infor_row {
    align-items: flex-start;
    .category_label {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: @black;
      display: flex;
      align-items: center;
      margin-top: 4px;
    }
    .ant-radio-group {
      padding-left: 10px;
    }
  }
  .radio_mt {
    .ant-radio-wrapper {
      margin-bottom: 10px;
    }
  }
}
.tooltip_description {
  max-width: 250px;
}

@media screen and (max-width: 768px) {
  .category {
    &_input {
      width: 100% !important;
    }
    .ant-card-body {
      padding: 20px 15px;
    }
    .ant-radio-wrapper {
      margin-right: 0;
      width: 50%;
    }
    &_block_infor {
      &_row {
        border-bottom: none;
      }
    }
    &_shape {
      display: flex;
      .ant-radio-wrapper {
        width: unset;
      }
    }
    .category_block_infor_row {
      .category_label {
        margin-top: 1px;
      }
    }
  }
}
