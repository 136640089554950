.footer {
  font-size: 14px;
  padding: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .contact-title {
    margin-top: 40px;
    .contact-title-text {
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
      color: @darken-primary;
      margin-bottom: 0px;
      text-align: center;
    }
  }

  .footer-contact {
    padding: 15px 0px;

    .footer-contact-item {
      background-size: cover;
      cursor: pointer;
      height: 180px;
      border: 1px solid @primary-color;
      margin: 15px 0px;
      padding-left: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      &-phone {
        margin-right: 15px;
        .text-phone {
          font-size: 30px;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 6px;
          color: @primary-color;
        }

        .text-date {
          font-size: 14px;
          font-weight: 500;
          color: @black-light;

          .text-time {
            font-size: 16px;
            font-weight: 700;
            margin-left: 10px;
          }
        }

        .text-des {
          font-size: 14px;
          font-weight: 500;
          color: @black-light;
        }
        &-content {
          flex-direction: column;
        }
      }

      &-email {
        margin-left: 15px;
        .text-mail {
          width: 210px;
          color: @primary-color;
          font-size: 30px;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 0px;

          .text-link {
            font-size: 30px;
            font-weight: 500;
            line-height: 0px;
            text-decoration-line: underline;
            color: @primary-color;
          }
        }
      }
      a {
        color: @white;
      }
    }
  }

  .footer-top {
    background: @darken-primary;

    .footer-menu {
      .footer-menu-item {
        cursor: pointer;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: @white;
          font-weight: 700;
        }
      }

      .title {
        color: @black;
        margin-right: 10px;
      }
    }
  }

  .footer-bottom {
    height: 32px;
    color: @black;
    background: @light-bg;
    text-align: center;
    padding: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    .footer-contact {
      .footer-contact-item {
        background-position-x: -30px;
        height: 140px;
        border: 1px solid @primary-color;
        margin: 15px 0px;
        padding-left: 45%;
        &-phone {
          .text-des {
            font-size: 12px;
          }
          &-content {
            width: 210px;
          }
        }
      }
    }
    .footer-top {
      .footer-menu {
        .footer-menu-item {
          height: 40px;
        }
      }
    }
    .footer-bottom {
      font-size: 10px;
    }
  }
}
