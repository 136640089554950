@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import '_variables';
@import '_container';
@import '_common';
@import '_fonts';
@import 'components/_button';
@import 'components/_custom_modal';
@import 'components/_card.less';
@import 'components/_formItem';
@import 'components/_header';
@import 'components/_header_restrict';
@import 'components/_menu';
@import 'components/_menu_mobile';
@import 'components/_search_mobile';
@import 'components/_group_field';
@import 'components/_topNav';
@import 'components/_topSearch';
@import 'components/_loading';
@import 'components/_footer';
@import 'components/_custom_tab';
@import 'components/_custom_table';
@import 'components/_custom_calendar';
@import 'components/_custom_description.less';
@import 'components/_list';
@import 'components/_section_title';
@import 'components/_modal_destination';
@import 'components/_date_picker.less';
@import 'components/_custom_breadcrumb.less';
@import 'components/_back_top.less';
@import 'components/_row_detail.less';
@import 'components/_modal_chamfer';
@import 'components/_date_range_picker.less';
@import 'components/_input_range_picker.less';
@import 'components/_plugin';
@import 'pages/_sign_in';
@import 'pages/_sign_up';
@import 'pages/_forgot_password';
@import 'pages/_reset_password';
@import 'pages/_home';
@import 'pages/_pricing';
@import 'pages/_account_setting.less';
@import 'pages/_quotation.less';
@import 'pages/_category.less';
@import 'pages/_order.less';
@import 'pages/_policy.less';
@import 'pages/_product_details.less';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

@primary-color: #ED1C24;@border-radius-base: 3px;