.ant-typography {
  overflow-wrap: normal;
  word-break: break-word;
}

.product_search {
  &_label {
    font-size: @sm;
    font-weight: @bold;
  }
  &_title {
    color: @darken-primary;
    font-size: @md;
    font-weight: @bold;
    margin-top: 20px;
  }
  &_item {
    .ant-radio-wrapper {
      margin-bottom: 23px;
      width: 25%;
      margin-right: 0;
    }
  }
  &_button {
    position: absolute;
    bottom: 20px;
    width: 170px;
    height: 44px;
  }
  &_list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 450px);
  }
  .ant-form-item-label {
    label {
      align-items: flex-start;
    }
  }
  .ant-form-item-control-input {
    align-items: flex-start;
  }

  &_shape {
    .ant-form-item-control {
      height: 44px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.search {
  padding: 20px;
  background-color: @white;
  .form_label {
    font-size: @sm;
    font-weight: @bold;
  }
  &_collapse {
    &_title {
      color: @darken-primary !important;
    }
    &_action {
      justify-content: flex-end;
      align-items: center;
      .ant-typography {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .row_display(@mb: 20px) {
    align-items: baseline;
    margin-bottom: @mb;
  }
  &_arrow {
    text-align: center;
  }
  &_delivery {
    .row_display();
    &_button {
      text-align: right;
    }
  }
  &_right {
    .ant-row {
      justify-content: flex-end;
    }
  }
  &_input {
    .ant-form-item-control-input {
      .row_display();
    }
  }
  &_radio {
    align-items: center;
    .ant-radio-wrapper {
      margin-right: 30px;
      margin-bottom: 13px;
    }
    &_quotation {
      align-items: baseline;
      .ant-form-item-label label {
        font-weight: 400;
      }
    }
  }
  &_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_divider {
    margin-top: 0;
  }
  &_button {
    text-align: center;
    button {
      width: 170px;
      height: 44px;
    }
  }
  .label_sort_order {
    font-size: 14px;
  }
  .search_button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.item {
  margin-bottom: 20px;
  &-info {
    flex-flow: row;
  }
  > div {
    align-items: center;
  }
  &_divider-vertical {
    .ant-divider-vertical {
      border-color: @white;
      height: 40px;
      margin-top: 3px;
    }
  }
  &_label {
    height: 60px;
    align-items: center;
    &_info {
      .ant-row {
        align-items: center;
      }
    }
    .ant-typography {
      color: @white;
      margin-bottom: 0;
    }
    &_button {
      text-align: end;
    }
    &_title {
      font-size: 16px;
      font-weight: 700;
    }
    &_name {
      font-size: 14px;
      font-weight: 700;
    }
    &_code {
      font-size: 12px;
      font-weight: 400;
    }
    &_sub {
      font-size: 12px;
      font-weight: 400;
    }
  }
  &_button {
    margin-left: 10px;
    width: 40px;
    height: 30px;
    button {
      width: 40px;
      height: 30px;
    }
  }
  &_data {
    padding: 20px;
    background-color: @white;
    &_label {
      margin-right: 25px;
      margin-bottom: 10px;
      display: inline-flex;
      .ant-tag-green {
        height: fit-content;
      }
      .ant-tag-blue {
        height: fit-content;
      }
      .ant-tag-default {
        height: fit-content;
        color: @grey-2;
      }
    }
    &_divider {
      display: none;
    }
    &_divider-bottom {
      margin-top: 0;
      margin-bottom: 10px;
      border-color: @grey-2;
    }
    .info {
      h5.ant-typography {
        margin-bottom: 0;
        font-size: 14px;
      }
      &_row {
        flex-flow: row;
        h5 {
          white-space: nowrap;
        }
      }
    }
    &_order {
      &_delivery {
        .info();
        padding: 10px 0;
        border-top: 1px solid @grey-2;
        border-bottom: 1px solid @grey-2;
      }
      &_weight {
        .info();
        padding: 10px;
        border: 1px solid @grey-2;
        justify-content: center;
      }
      &_unit {
        .info();
        padding: 10px;
        border: 1px solid @grey-2;
        border-left: none;
        justify-content: center;
      }
      &_total {
        .info();
        padding: 10px;
        border-top: 1px solid @grey-2;
        border-bottom: 1px solid @grey-2;
        background-color: @primary-color;
        justify-content: center;
        .ant-typography {
          color: @white;
        }
      }
      &_date {
        .ant-typography {
          color: @primary-color;
          font-weight: 700;
          &:last-child {
            margin-left: 20px;
          }
        }
      }
      &_tax {
        .info();
        padding-bottom: 10px;
        h5 {
          margin-right: 10px;
        }
      }
    }
  }
  &_expired {
    .ant-typography {
      color: @grey-3;
    }
    &_label {
      background-color: @grey-3;
      .ant-typography {
        color: @white;
      }
    }
    &_date {
      color: @grey-2 !important;
    }
    &_total {
      background-color: @grey-2;
      .ant-typography {
        color: @white;
      }
    }
  }
}

.border-right {
  border-right: 1px solid @grey-2;
  &-expired {
    border-right-color: @white !important;
  }
}
.date_to_deliver {
  margin-left: 0px !important;
  display: block;
}

.quotation {
  .data {
    align-items: center;
    padding-right: 30%;
    background-color: @grey-4;
    &_size {
      &_col {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &_text {
          margin: 0 10px;
        }
      }
      &_button {
        text-align: end;
        button {
          width: 44px;
          height: 24px;
          padding: 0;
          font-size: 14px;
        }
      }
      &_select {
        margin-bottom: 0;
        .ant-select-selector {
          border-color: @black;
          height: 24px !important;
          display: flex;
          align-items: center;
        }
      }
    }
    &_pagination {
      text-align: end;
      .ant-pagination-item-active {
        background: unset;
      }
    }
    &_divider {
      border-color: @black;
      height: 23px;
    }
  }

  .note {
    background-color: @grey-4;
    padding-top: 60px;
    &_error {
      padding-left: 40px;
    }
    &_sub {
      background-color: @yellow;
      padding: 8px 40px;
      color: @white;
      font-size: 12px;
      .ant-typography {
        color: @white;
        font-size: 12px;
        font-weight: 400;
        margin-left: 3px;
      }
    }
  }

  &_table {
    margin-top: 20px;
    .ant-table-row-selected > td {
      background-color: unset !important;
    }
    .table-row-expired {
      background-color: @grey-3 !important;
    }
    .ant-table-expand-icon-col {
      width: 0;
    }
    .ant-table-row-expand-icon {
      display: none;
    }
    .ant-table-row {
      background-color: @darken-primary;
    }
    .ant-table-cell:last-child {
      padding-right: 20px !important;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background-color: unset;
    }
    .ant-table-pagination-right:first-child {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 85px;
      align-items: baseline;
      .ant-pagination-item {
        background-color: unset;
      }
      .ant-pagination-total-text {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          span {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      .ant-select-selector {
        height: 24px;
        width: 65px;
        align-items: center;
      }
    }
    .ant-table-pagination-right:last-child {
      .ant-pagination-total-text {
        display: none;
      }
      .ant-pagination-item {
        background-color: unset;
      }
    }

    .ant-table-thead {
      .ant-table-cell:not(:nth-child(2)) {
        display: none;
      }
      .ant-table-cell:nth-child(2) {
        position: absolute;
        top: 18px;
        left: 16px;
        border-bottom: unset;
        background-color: white;
      }
    }
    .ant-table-title {
      padding: 0 !important;
    }
    .all {
      background-color: @white;
      padding: 20px;
      align-items: baseline;
      &_action {
        text-align: end;
        &_button {
          margin-left: 10px;
          font-size: 14px;
        }
      }
      &_checkbox {
        padding-left: 35px;
      }
    }

    .order {
      &_mobile {
        display: none;
      }
    }

    .row {
      align-items: center;
      .ant-typography {
        color: @white;
        margin-bottom: 0;
      }
      &_info {
        display: none;
      }
      &_divider {
        display: none;
      }
      &_button {
        display: none;
      }
    }
    .ant-table-expanded-row {
      .ant-table-cell {
        padding: 0 !important;
      }
      > td {
        background-color: @light-bg;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .item {
    &_label {
      &_button {
        button {
          margin-left: 1px;
        }
      }
    }
    &_button {
      margin-left: 1px;
    }
  }

  .quotation {
    .all {
      &_checkbox {
        .ant-checkbox + span {
          padding-right: 0;
        }
      }
      &_action {
        &_button {
          margin-left: 3px;
          padding: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product_search {
    .ant-radio-wrapper {
      margin-right: 0;
      width: 50%;
    }

    &_shape {
      display: flex;
      .ant-radio-wrapper {
        width: unset;
      }
    }
    &_list {
      max-height: calc(100vh - 460px);
    }
    &_category {
      .ant-form-item-control {
        .ant-radio-wrapper {
          width: 50%;
        }
      }
    }
  }
  .search {
    &_collapse {
      &_title {
        font-size: 30px !important;
      }
    }
  }
  .item {
    margin-bottom: 0;
    > div {
      align-items: unset;
    }
    position: relative;
    &_divider-horizontal {
      .ant-divider-horizontal {
        margin: 10px 0 0 0;
      }
    }
    &_label {
      height: auto;
      padding: 0;
      &_info {
        background-color: @white;
        padding: 10px;
        .ant-typography {
          color: @black;
          padding: 0;
        }
      }
      &_sub {
        color: @primary-color !important;
      }
      &_button {
        width: 100%;
        background-color: @white;
        padding: 0 10px 10px 10px;
        button {
          margin-left: 10px;
        }
      }
    }
    &_checkbox {
      position: absolute;
      z-index: 1;
      left: 10px;
    }
    &_data {
      padding: 10px;
      padding-top: 0;
      &_label {
        margin-right: 0;
        margin-bottom: 10px;
        display: block;
      }
      &_divider {
        display: block;
        margin-top: 0;
        margin-bottom: 10px;
      }
      &_order {
        &_weight {
          border: none;
          padding-left: 0;
        }
        &_unit {
          padding-left: 0;
          border-right: none;
          border-bottom: none;
        }
        &_note {
          display: block;
        }
        &_total {
          .ant-row {
            justify-content: space-between;
          }
        }
        &_date {
          display: flex;
          flex-direction: column;
          .ant-typography:last-child {
            margin-left: 0;
          }
        }
        &_tax {
          h5 {
            width: 100%;
          }
        }
      }
    }
    &_expired {
      &_label {
        &_info {
          .ant-typography {
            color: @grey-3;
          }
        }
      }
    }
  }

  .quotation {
    &_table {
      .ant-table-thead {
        .ant-table-cell:nth-child(2) {
          top: 10px;
          left: 16px;
        }
      }
      .row {
        .ant-typography {
          color: @black;
        }
        &_info {
          display: block;
          padding: 10px;
          background-color: @white;
        }
        &_name {
          font-weight: 700;
        }
        &_sub {
          color: @primary-color !important;
        }
        &_divider {
          display: block;
          margin: 10px 0;
        }
        &_button {
          display: block;
          text-align: end;
        }
      }
      .item {
        &_label {
          &_title {
            color: @white;
            margin-bottom: 0px;
          }
        }
      }
      .ant-table-pagination-right:first-child {
        margin-top: 0;
        .ant-pagination-total-text {
          width: 100%;
          text-align: end;
          .ant-space-item:last-child {
            display: none;
          }
        }
      }

      .ant-table-tbody > tr > td:nth-child(3) {
        width: 100%;
      }
      .ant-table-tbody > tr > td:nth-child(4) > div {
        display: none;
      }
      .ant-table-tbody > tr > td:nth-child(5) > button {
        display: none;
      }
      .item_divider-vertical {
        .ant-divider-vertical {
          height: 100%;
        }
      }
    }
    .note > div {
      padding: 10px;
      font-weight: 400;
    }
    .note {
      padding-top: 90px;
    }
    .all {
      padding: 10px;
      align-items: center;
      &_checkbox {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      &_action {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        &_note {
          margin-left: 5px;
          &_last {
            display: none;
          }
        }
        &_button {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 9px;
          padding: 0;
          width: 56px;
          justify-content: center;
          white-space: pre-line;
          height: 50px;
          .anticon {
            font-size: 14px;
          }
          span:last-child {
            margin-left: 0;
          }
        }
      }
    }
    .order {
      &_desktop {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .product_modal {
    padding: 0 !important;
  }
}

@media screen and (max-width: 360px) {
  .product_search {
    &_list {
      max-height: calc(100vh - 530px);
    }
  }
}
